import AOS from 'aos';
import Fancybox from '@fancyapps/fancybox/dist/jquery.fancybox.min.js';
import initializedMenu from '../lib/mmenu';

export default {
	init() {
		// JavaScript to be fired on all pages

		initializedMenu();
		AOS.init();

		// Gallery captions
		Fancybox.bind( '[data-fancybox]', {
			Toolbar: false,
			caption: function ( fancybox, carousel, slide ) {
				return slide.$trigger.title;
			},
		} );
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
